import styled, { themeGet } from "@styled"

export const ContactoInfoWrapper = styled.div`
  background-color: #fff;
  padding: 60px 0;
`
export const InfoItem = styled.div`
  margin-bottom: 30px;
  p {
    line-height: 1.5;
    margin-bottom: 0;
  }
  i {
    font-size: 44px;
    color: ${themeGet("colors.background")};
    min-width: 30px;
  }
`

export const InfoTitle = styled.h5`
  color: #000;
  margin-bottom: 4px;
`
