import React from "react"
import SectionTitle from "@ui/section-title"
import { Container, Row, Col } from "@ui/wrapper"
import ContactForm from "@components/forms/contact-form"
import { StyledSection } from "./style"

const ContactoArea = () => {
  return (
    <StyledSection>
      <Container pt="80px">
        <Row alignItems="center">
          <Col lg={6}>
            <SectionTitle
              layout="2"
              textAlign="left"
              mb="30px"
              mt="60px"
              title="Agenda una cita"
              subtitle="En Prodental estaremos encantados de recibirte en nuestro
      consultorio para tratar tus problemas dentales."
            />
            <ContactForm />
          </Col>
          <Col lg={6}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902.5246082168724!2d-77.08747278350678!3d-12.007361291493574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105ce8e78bc679d%3A0x8bcea8fb59a2946a!2sAv.%20Ang%C3%A9lica%20Gamarra%202180%2C%20Cercado%20de%20Lima%2015108!5e0!3m2!1ses-419!2spe!4v1635905717765!5m2!1ses-419!2spe"
              width="450"
              height="450"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}

export default ContactoArea
