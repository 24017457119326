import styled, { device } from "@styled"
import BackgroundImage from "gatsby-background-image"

export const PageHeaderWrap = styled(BackgroundImage)`
  padding-block-start: 30px;
  padding-block-end: 15px;
  ${device.medium} {
    padding-block-start: 50px;
    padding-block-end: 25px;
  }
  ${device.large} {
    padding-block-start: 96px;
    padding-block-end: 48px;
  }
`

export const StyledTitle = styled.h1`
  margin-block-end: 15px;
`

export const StyledDesc = styled.h5`
  color: #fff;
  font-weight: 400;
`
