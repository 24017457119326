import React from "react"
import { Container, Row, Col } from "@ui/wrapper"
import {
  ContactoInfoWrapper,
  InfoItem,
  InfoTitle,
} from "./contacto-info-area.style"

const ContactoInfoArea = data => {
  const { address, phone, phone2, phone3, email } = data.data.contact
  return (
    <ContactoInfoWrapper>
      <Container>
        <Row textAlign="center">
          <Col md={4}>
            <InfoItem>
              <i class="fal fa-map-marker-alt"></i>
              <InfoTitle>Dirección</InfoTitle>
              <p>{address}</p>
            </InfoItem>
          </Col>
          <Col md={4}>
            <InfoItem>
              <i class="fas fa-phone-volume"></i>
              <InfoTitle>Teléfono</InfoTitle>
              <p>{phone}</p>
              <p>{phone2}</p>
              <p>{phone3}</p>
            </InfoItem>
          </Col>
          <Col md={4}>
            <InfoItem>
              <i class="far fa-envelope"></i>
              <InfoTitle>Correos</InfoTitle>
              <p>{email}</p>
            </InfoItem>
          </Col>
        </Row>
      </Container>
    </ContactoInfoWrapper>
  )
}

export default ContactoInfoArea
