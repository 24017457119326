import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { Container, Row, Col } from "@ui/wrapper"
import { PageHeaderWrap, StyledTitle, StyledDesc } from "./style"
import { HeadingType, TextType, ImageType } from "@utils/types"

const PageHeader = ({ data, title, subtitle }) => {
  const pageHeaderData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "header.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const bgImage = convertToBgImage(
    getImage(pageHeaderData.file.childImageSharp)
  )
  return (
    <PageHeaderWrap {...bgImage}>
      <Container>
        <Row>
          <Col lg={8}>
            <StyledTitle>{title}</StyledTitle>
            <StyledDesc>{subtitle}</StyledDesc>
          </Col>
        </Row>
      </Container>
    </PageHeaderWrap>
  )
}

PageHeader.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
  }),
}

export default PageHeader
