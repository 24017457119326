import * as React from "react"
import PropTypes from "prop-types"
import Seo from "@components/seo"
import PageHeader from "@ui/page-header/layout-01"
import Layout from "@layout"
import TestimoniosArea from "@containers/index/testimonios-area"
import Header from "@layout/header/layout-03"
import Footer from "@layout/footer/layout-01"
import ContactoArea from "@containers/contacto/contacto-area/layout-05"
import ContactoInfoArea from "@containers/contacto/contacto-info-area"
import { headerData, footerData } from "@constants"

const ContactoPage = ({ location, pageContext }) => (
  <Layout location={location}>
    <Seo title="Inicio" />
    <Header data={headerData} />

    <main className="site-wrapper-reveal">
      <PageHeader
        pageContext={pageContext}
        location={location}
        title="Contacta con nosotros"
        subtitle="Puedes llamarnos directamente y contactar con un miembro de nuestro equipo."
      />
    </main>
    <ContactoArea />
    <ContactoInfoArea data={headerData} />
    <TestimoniosArea />
    <Footer data={footerData} />
  </Layout>
)

ContactoPage.propTypes = {
  pageContext: PropTypes.shape({}),
  location: PropTypes.shape({}),
}

export default ContactoPage
