import styled, { device, themeGet } from "@styled"

export const StyledSection = styled.section`
  background-color: #fff;
  position: relative;
  padding-block-start: 0;
  padding-block-end: 60px;
  ${device.medium} {
    padding-block-start: 0;
    padding-block-end: 80px;
  }
  ${device.large} {
    padding-block-start: 0;
    padding-block-end: 100px;
  }
  input {
    color: ${themeGet("colors.primary")};
    background-color: #fff;
    border: 1px solid ${themeGet("colors.primary")};
  }
  textarea {
    color: ${themeGet("colors.primary")};
    background-color: #fff;
    border: 1px solid ${themeGet("colors.primary")};
  }
`
